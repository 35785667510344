import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cacheKeys from "storeseo-enums/cacheKeys";
import DismissableBanner from "../common/DismissableBanner";

export default function EnableMultiLanguage() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  if (!user?.multiLanguage?.hasMultipleLanguages || user?.multiLanguage?.enabledMultiLanguage) return null;

  return (
    <DismissableBanner
      title={t("Enable Multilingual SEO")}
      content={t(
        "It seems your store is available in multiple languages. To optimize your store’s performance and reach a broader audience, enable the Multilingual option in the StoreSEO app. Go to the settings page and follow the instructions to enable it."
      )}
      secondaryAction={{
        content: t("Enable Multilingual SEO"),
        url: "/settings/multi-language",
        variant: "secondary",
      }}
      bannerKey={cacheKeys.BANNER_ENABLE_MULTI_LANGUAGE}
    />
  );
}
