import { Badge, Card, Collapsible, Divider, Icon, InlineStack, Text } from "@shopify/polaris";
import { ChevronDownIcon, ChevronUpIcon } from "@shopify/polaris-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { collapseHeaderStyle } from "../../styles/common";

export default function CollapsibleCard({
  children,
  title,
  suffix = null,
  badgeText = "",
  badgeStatus = "",
  action = null,
}) {
  const { t } = useTranslation();
  const [cardOpen, setCardOpen] = useState(true);

  return (
    <Card padding="0">
      <div
        className="collapse-header"
        onClick={() => setCardOpen(!cardOpen)}
        aria-expanded={cardOpen}
        aria-controls="basic-collapsible"
        style={collapseHeaderStyle}
      >
        <InlineStack
          wrap={false}
          gap="050"
        >
          <Text
            as="h6"
            variant="headingSm"
          >
            {t(title)}
          </Text>
          {badgeText && (
            <>
              {" "}
              <Badge
                size="small"
                tone={badgeStatus}
              >
                {t(badgeText)}
              </Badge>
            </>
          )}
          {suffix}
        </InlineStack>
        <InlineStack
          wrap={false}
          gap="200"
        >
          {action}
          <Icon
            source={cardOpen ? ChevronUpIcon : ChevronDownIcon}
            tone="base"
          />
        </InlineStack>
      </div>
      <Divider />
      <Collapsible
        open={cardOpen}
        id="basic-collapsible"
        transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
        expandOnPrint
      >
        {children}
      </Collapsible>
    </Card>
  );
}
