import SelectInput from "@/components/common/SelectInput";
import { setUser } from "@/store/features/User";
import queryKeys from "@/utility/queryKeys";
import { GlobeIcon } from "@shopify/polaris-icons";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useShopApi } from "./apiHooks";
import { useAppQuery } from "./useAppQuery";

export const useMultiLanguageSetting = () => {
  const user = useSelector((state) => state.user);
  const hasMultiLanguagePermission = user?.permission?.multi_language_seo;

  const dispatch = useDispatch();

  const shopApi = useShopApi();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLocaleToSync, setSelectedLocaleToSync] = useState("");

  const { isFetching, data, refetch } = useAppQuery({
    queryKey: [queryKeys.MULTI_LANGUAGE_SETTING],
    queryFn: shopApi.getMultiLanguageSetting,
    reactQueryOptions: {
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchInterval: false,
    },
  });

  const { isLoading: isTogglingMultiLanguageSupport, mutate: toggleMultiLanguageSupport } = useMutation({
    mutationFn: () => shopApi.toggleMultiLanguageSupport(!data?.enabled),
    onSuccess: (enabled) => {
      queryClient.setQueryData([queryKeys.MULTI_LANGUAGE_SETTING], {
        ...data,
        enabled,
      });

      dispatch(setUser({ ...user, multiLanguage: { ...user.multiLanguage, enabledMultiLanguage: enabled } }));
    },
  });

  const { mutate: syncMultiLanguageData } = useMutation({
    mutationFn: (locale) => shopApi.syncMulitLanguageData(locale),
    onMutate: (locale) => setSelectedLocaleToSync(locale),
    onSuccess: (enabled, locale) => {
      const shopLocales = data.shopLocales.map((l) => {
        if (l.locale !== locale) return l;

        return {
          ...l,
          syncOngoing: true,
        };
      });
      queryClient.setQueryData([queryKeys.MULTI_LANGUAGE_SETTING], {
        ...data,
        shopLocales,
      });
      setSelectedLocaleToSync("");
    },
  });

  const primaryLanguage = !data ? "" : data.shopLocales.find((l) => l.primary)?.locale;
  const selectedLanguageName = data?.shopLocales.find((l) => l.locale === selectedLanguage)?.name || null;
  const multiLanguageEnabled = data?.enabled && hasMultiLanguagePermission;
  const usingMultiLanguageMode = multiLanguageEnabled && selectedLanguage !== primaryLanguage;

  /** @type {{locale: string, name: string, primary: boolean, published: boolean, syncOngoing: boolean}[]} */
  const shopLocales = data?.shopLocales || [];

  const languagePickerOptions = shopLocales.map((l) => ({
    label: `${l.name}${l.locale === primaryLanguage ? " (Default)" : ""}`,
    value: l.locale,
  }));
  const selectedOption = selectedLanguage
    ? languagePickerOptions.find((l) => l.value === selectedLanguage)
    : languagePickerOptions.find((l) => l.value === primaryLanguage);

  useEffect(
    function updateSelectedLanguage() {
      const { languageCode = "default" } = Object.fromEntries(searchParams);

      setSelectedLanguage(languageCode !== "default" && multiLanguageEnabled ? languageCode : primaryLanguage);
    },
    [searchParams, primaryLanguage]
  );

  const handleLanguageSelect = (option) => {
    const searchParamsQuery = Object.fromEntries(searchParams.entries());
    delete searchParamsQuery.page;

    searchParamsQuery.languageCode = option.value;
    setSearchParams(searchParamsQuery);
  };

  const LanguagePicker = useMemo(
    () => () =>
      multiLanguageEnabled ? (
        <SelectInput
          options={languagePickerOptions}
          icon={GlobeIcon}
          selected={selectedOption}
          onSelect={handleLanguageSelect}
          variant={"tertiary"}
        />
      ) : null,
    [searchParams, selectedLanguage, data, user]
  );

  const languageActions = useMemo(() => {
    if (!multiLanguageEnabled) return [];

    return [
      {
        title: selectedLanguageName,
        icon: GlobeIcon,
        actions: languagePickerOptions.map((l) => ({
          content: l.label,
          active: l.value === selectedLanguage,
          onAction: () => {
            handleLanguageSelect(l);
          },
        })),
      },
    ];
  });

  return {
    refetch,
    isFetching,

    enabled: multiLanguageEnabled,
    usingMultiLanguageMode,
    primaryLanguage,
    shopLocales,

    toggleMultiLanguageSupport,
    isTogglingMultiLanguageSupport,

    selectedLocaleToSync,
    syncMultiLanguageData,

    selectedLanguage,
    selectedLanguageName,
    LanguagePicker,
    languageActions,
  };
};
