//@ts-check
import useBackupRestoreApi from "@/hooks/apiHooks/useBackupRestoreApi";
import queryKeys from "@/utility/queryKeys";
import { useMutation, useQuery } from "react-query";
import { backupAndRestoreDatabaseSchema } from "storeseo-schema/settings/backupAndRestore";

const { BACKUP_LIST } = queryKeys;
/**
 * @param {Object} [queryParams]
 * @param {string} [queryParams.sortBy]
 * @param {string} [queryParams.sortOrder]
 * @param {import("react-query").UseQueryOptions} [queryConfig]
 * @returns {import("react-query").UseQueryResult<import("yup").InferType<typeof backupAndRestoreDatabaseSchema>[]>}
 */
export function useBackupList(queryParams, queryConfig = {}) {
  const { backupList } = useBackupRestoreApi();
  const queryKey = queryParams ? [BACKUP_LIST, queryParams] : [BACKUP_LIST];
  return useQuery({
    queryFn: () => backupList(queryParams),
    refetchOnWindowFocus: false,
    queryKey,
    ...queryConfig,
  });
}

export function useCreateBackup() {
  const { createBackup } = useBackupRestoreApi();
  return useMutation({
    mutationFn: createBackup,
  });
}

export function useDeleteBackup() {
  const { deleteBackup } = useBackupRestoreApi();
  return useMutation({
    mutationFn: deleteBackup,
  });
}

export function useRestoreBackup() {
  const { restoreBackup } = useBackupRestoreApi();
  return useMutation({
    mutationFn: restoreBackup,
  });
}

/**
 *
 * @param {import("react-query").UseQueryOptions} queryConfig
 * @returns {import("react-query").UseQueryResult<Awaited<ReturnType<backupRestoreStatus>>>}
 */
export function useBackupRestoreStatus(queryConfig = {}) {
  const { backupRestoreStatus } = useBackupRestoreApi();
  return useQuery({
    queryFn: backupRestoreStatus,
    refetchOnWindowFocus: false,
    ...queryConfig,
  });
}
