// //@ts-check
import { showNotification } from "@/utility/helpers";
import { useTranslation } from "react-i18next";
import { backupAndRestoreDatabaseSchema, backupAndRestoreSchema } from "storeseo-schema/settings/backupAndRestore";
import { useApiClient } from "../useApiClient";

export default function useBackupRestoreApi() {
  const { t } = useTranslation();
  const apiClient = useApiClient();

  /**
   * @param {{sortBy?: string, sortOrder?: string}} query
   * @returns {Promise<import("yup").InferType<typeof backupAndRestoreDatabaseSchema>>[]}
   */
  const backupList = async (query) => {
    try {
      const { data } = await apiClient(`/backup-restore`, {
        method: "GET",
        query,
      });

      return data;
    } catch (err) {
      console.log("Error getting backup data list: ", err);
      throw new Error("Something went wrong!");
    }
  };

  /**
   *
   * @returns {Promise<import("yup").InferType<{hasBackupInProgress: boolean, hasRestoreInProgress: boolean}>>}
   */
  const backupRestoreStatus = async () => {
    try {
      const { hasBackupInProgress, hasRestoreInProgress } = await apiClient(`/backup-restore/status`, {
        method: "GET",
      });

      return { hasBackupInProgress, hasRestoreInProgress };
    } catch (err) {
      console.log("Error getting backup restore status: ", err);
      throw new Error("Something went wrong!");
    }
  };

  /**
   *
   * @param {import("yup").InferType<typeof backupAndRestoreSchema>} body
   * @returns {Promise<import("yup").InferType<typeof backupAndRestoreDatabaseSchema>>}
   */
  const createBackup = async (body) => {
    try {
      const { message, data } = await apiClient(`/backup-restore`, {
        method: "PUT",
        body: JSON.stringify(body),
      });

      showNotification({
        type: "success",
        message: t(message),
      });

      return data;
    } catch (err) {
      console.log("Error creating backup: ", err);
      if (err instanceof Response) {
        return Promise.reject(await err.json());
      }
      throw new Error("Something went wrong!");
    }
  };

  /**
   * @param {{id: number}} body
   */
  const restoreBackup = async ({ id }) => {
    try {
      const { message } = await apiClient(`/backup-restore/${id}`, {
        method: "POST",
      });

      showNotification({
        type: "success",
        message: t(message),
      });
    } catch (err) {
      console.log("Error restoring backup: ", err);
      throw new Error("Something went wrong!");
    }
  };

  /**
   *
   * @param {{id: number}} body
   */
  const deleteBackup = async ({ id }) => {
    try {
      const { message } = await apiClient(`/backup-restore/${id}`, {
        method: "DELETE",
      });

      showNotification({
        type: "success",
        message: t(message),
      });
    } catch (err) {
      console.log("Error deleting backup: ", err);
      throw new Error("Something went wrong!");
    }
  };

  return { createBackup, backupList, backupRestoreStatus, restoreBackup, deleteBackup };
}
