// import seoApps from "../../backend/enums/seoApps";
import {
  AdjustIcon,
  AppExtensionIcon,
  ArrowsOutHorizontalIcon,
  ChartVerticalIcon,
  ConnectIcon,
  CursorOptionIcon,
  DnsSettingsIcon,
  DomainIcon,
  EmailIcon,
  ImageMagicIcon,
  ImageWithTextOverlayIcon,
  LanguageIcon,
  MagicIcon,
  ShopcodesIcon,
} from "@shopify/polaris-icons";
import mitt from "mitt";
import seoApps from "storeseo-enums/seoApps";
import { AI_OPTIMIZER, IMAGE_OPTIMIZER } from "storeseo-enums/subscriptionAddonGroup";
import subscriptionAddonInterval from "storeseo-enums/subscriptionAddonInterval.js";
import ImageOptimizer from "../components/onboard/ImageOptimizer.jsx";
import InstalledApps from "../components/onboard/InstalledApps";
import SeoSetting from "../components/onboard/SeoSetting";
import StoreAnalysis from "../components/onboard/StoreAnalysis";
import StoreInformation from "../components/onboard/StoreInformation";
import Documentation from "../components/svg/Documentation.jsx";
import Halloween2023 from "../components/svg/Halloween2023.jsx";
import StoreSEOWalkthrough from "../components/svg/StoreSEOWalkthrough.jsx";
import Support from "../components/svg/Support.jsx";
import Tutorial from "../components/svg/Tutorial.jsx";
import langs from "./languages";

export default {
  baseURL: process.env.FRONTEND_BASE_URL,
  apiKey: process.env.FRONTEND_API_KEY,
  host: process.env.FRONTEND_HOST,
  appVersion: process.env.FRONTEND_APP_VERSION || "1.0.0",
};

export const emitter = mitt();

export const browserEvents = {
  SAVE_CHANGES: "SAVE_CHANGES",
  PAGE_DATA_LOADED: "PAGE_DATA_LOADED",
};

export const colors = {
  primary: "#00CC76",
  danger: "#FE504F",
  warning: "#FFA53C",
  info: "#597DFC",
};

export const labelColors = {
  danger: "critical",
  warning: "attention",
  primary: "success",
};

export const onboardUrls = {
  storeInformation: "/onboard",
  seoSettings: "/onboard/seo-setting",
  analysis: "/onboard/analysis",
  optimization: "/onboard/optimization",
  success: "/onboard/success",
  success: "/onboard/verify",
};

export const ONBOARD_STEPS = [
  {
    step: 1,
    Component: StoreInformation,
  },
  {
    step: 2,
    Component: InstalledApps,
  },
  {
    step: 3,
    Component: StoreAnalysis,
  },
  {
    step: 4,
    Component: SeoSetting,
  },
  {
    step: 5,
    Component: ImageOptimizer,
  },
  // {
  //   step: 6,
  //   Component: EnableAppEmbed,
  // },
];

export const onboardSteps = [
  {
    step: 1,
    title: "Store Information",
    url: onboardUrls.storeInformation,
    active: false,
  },
  {
    step: 3,
    title: "SEO Settings",
    url: onboardUrls.seoSettings,
    active: false,
  },
  {
    step: 4,
    title: "Analysis",
    url: onboardUrls.analysis,
    active: false,
  },
  {
    step: 5,
    title: "Optimization",
    url: onboardUrls.optimization,
    active: false,
  },
  {
    step: 6,
    title: "Success",
    url: onboardUrls.success,
    active: false,
  },
];

export const STORE_SEO_ADDRESS = {
  address1: "Milton",
  address2: "124 Broadkill Rd #599",
  city: "Delaware",
  zip: "19968",
  country: "United States",
};

export const STORE_SEO_SUPPORT_EMAIL = "support@storeseo.com";
export const STORE_SEO_TITLE = "StoreSEO";

export const PUBLISH_STATUS = {
  draft: "Draft",
  published: "Published",
};

export const META_TITLE_CHAR_LIMIT = 70;
export const META_DESC_CHAR_LIMIT = 165;
export const MAX_FILE_SIZE_IN_KB = 850;

export const languages = langs;

export const onboardingSeoSettingOptions = {
  ...Object.values(seoApps).reduce((apps, appConfig) => ({ ...apps, [appConfig.name]: appConfig.name }), {}),
  KEEP_EXISTING_SETTINGS: "KEEP_EXISTING_SETTINGS",
  CUSTOM_SEO_TEMPLATE: "CUSTOM_SEO_TEMPLATE",
};

export const breadcrumbType = {
  PRODUCT_VIEW: "PRODUCT_VIEW",
  PRODUCT_EDIT: "PRODUCT_EDIT",
  PAGE_VIEW: "PAGE_VIEW",
  PAGE_EDIT: "PAGE_EDIT",
  ARTICLE_VIEW: "ARTICLE_VIEW",
  ARTICLE_EDIT: "ARTICLE_EDIT",
};

export const basicBreadcrumbs = {
  [breadcrumbType.PRODUCT_VIEW]: [
    {
      title: "Home",
      url: "/",
      isActive: false,
    },
    {
      title: "Products",
      url: "/products",
      isActive: false,
    },
  ],
  [breadcrumbType.PRODUCT_EDIT]: [
    {
      title: "Home",
      url: "/",
      isActive: false,
    },
    {
      title: "Products",
      url: "/products",
      isActive: false,
    },
  ],
  [breadcrumbType.PAGE_VIEW]: [
    {
      title: "Home",
      url: "/",
      isActive: false,
    },
    {
      title: "Pages",
      url: "/pages",
      isActive: false,
    },
  ],
  [breadcrumbType.PAGE_EDIT]: [
    {
      title: "Home",
      url: "/",
      isActive: false,
    },
    {
      title: "Pages",
      url: "/pages",
      isActive: false,
    },
  ],
  [breadcrumbType.ARTICLE_VIEW]: [
    {
      title: "Home",
      url: "/",
      isActive: false,
    },
    {
      title: "Blog Posts",
      url: "/blogs",
      isActive: false,
    },
  ],
  [breadcrumbType.ARTICLE_EDIT]: [
    {
      title: "Home",
      url: "/",
      isActive: false,
    },
    {
      title: "Blog Posts",
      url: "/blogs",
      isActive: false,
    },
  ],
};

export const previewImgType = {
  FACEBOOK: "facebook_preview_image",
  TWITTER: "twitter_preview_image",
};

export const sortByFields = {
  TITLE: "title",
  DATE: "created_at",
  ISSUES: "issues",
  SCORE: "score",
};

export const sortOrders = {
  ASC: "ASC",
  DESC: "DESC",
};

export const SORT_DIR = {
  ASC: "ascending",
  DESC: "descending",
};

export const productStatusList = [
  {
    label: "All",
    value: 1,
  },
  {
    label: "Optimized",
    value: 2,
  },
  {
    label: "SEO Issues",
    value: 3,
  },
];

export const productLimitList = [
  {
    value: 20,
    label: "20 Products",
  },
  {
    value: 50,
    label: "50 Products",
  },
  {
    value: 100,
    label: "100 Products",
  },
];

export const sitemapStatusList = [
  { value: "-1", label: "All" },
  { value: "0", label: "Active" },
  { value: "1", label: "Inactive" },
];

export const noIndexStatusList = [
  { value: "-1", label: "All" },
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

export const noFollowStatusList = [
  { value: "-1", label: "All" },
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

export const SCHEDULE_A_CALL_URL = "https://storeseo.com/go/talk-with-seo-expert";
export const DOCUMENTATION_URL = "https://storeseo.com/docs";
export const TUTORIAL_URL = "https://www.youtube.com/@StoreSEO";
export const SUPPORT_URL = "https://storeseo.com/support";
export const WALKTHROUGH_URL = "https://storeseo.com/go/storeseo-walkthrough-meeting";
export const VERIFY_URL = "https://storeseo.com/go/storeseo-verify-store";
export const CHANGELOG_URL = "https://storeseo.com/changelog/";
export const STOREWARE_URL = "https://storeware.io/";
export const SEO_GUIDE_URL = "https://storeseo.com/go/download-seo-guide";

export const HELP_URLS = {
  IMAGE_OPTIMIZER: "https://storeseo.com/docs/monthly-usage-limit-for-image-optimizer/",
  AI_OPTIMIZER: "https://storeseo.com/docs/credits-ai-content-optimizer",
};

export const socialMediaLabels = {
  facebookURL: "Facebook",
  instagramURL: "Instagram",
  twitterURL: "X",
  linkedinURL: "LinkedIn",
  pinterestURL: "Pinterest",
  youtubeURL: "YouTube",
};

export const dashboardCardContents = [
  {
    key: "tutorial",
    title: "Tutorial",
    content: "Check out our collection of video tutorials to get started",
    actionText: "See tutorials",
    url: TUTORIAL_URL,
    Illustration: Tutorial,
  },
  {
    key: "documentation",
    title: "Documentation",
    content: "Read our step-by-step guidelines for each and every feature",
    actionText: "Read documentation",
    url: DOCUMENTATION_URL,
    Illustration: Documentation,
  },
  {
    key: "support",
    title: "Support",
    content: "Feel free to reach out to us at any time to solve your issue",
    actionText: "Contact support",
    url: SUPPORT_URL,
    Illustration: Support,
  },
  {
    key: "storeseo-walkthrough",
    title: "StoreSEO Walkthrough",
    content: "Talk with our experts and get a complete StoreSEO Walkthrough",
    actionText: "Schedule a call",
    url: WALKTHROUGH_URL,
    Illustration: StoreSEOWalkthrough,
  },
];

export const halloween2023 = {
  title: "Halloween treat: Up to 35% discount",
  content: "Save big on StoreSEO PRO & take your SEO experience to the next level",
  actionText: "Grab the deal now",
  Illustration: Halloween2023,
};

export const generalSettingToggleContents = [
  { key: "JSON_LD", label: "JSON-LD", content: "Turn on JSON-LD to get rich results on web search" },
  {
    key: "GOOGLE_INDEXING",
    label: "Google Indexing",
    content: "Turn on Google Indexing to auto index your products to Google",
  },
  {
    key: "REDIRECT_OUT_OF_STOCK",
    label: "Redirect on out of stock",
    content: "Turn this on to redirect users to another page when a product is out of stock",
  },
];

export const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

export const dashboardNavigationCardContents = [
  {
    key: "pages",
    title: "Pages",
    contents: "Rank your landing pages on search engines",
    action: {
      title: "Optimize your pages",
      url: "/pages",
    },
  },

  {
    key: "articles",
    title: "Blog Posts",
    contents: "Boost organic traffic by optimizing your blogs",
    action: {
      title: "Optimize your blogs",
      url: "/articles",
    },
  },
];

export const menus = [
  {
    label: "Home",
    destination: "/",
  },
  {
    label: "Optimize SEO",
    destination: "/optimize-seo",
  },
  {
    label: "Image Optimizer",
    destination: "/image-optimizer",
  },
  {
    label: "Image Alt Text Generator",
    destination: "/image-alt-text",
  },
  {
    label: "SEO Schema",
    destination: "/local-seo",
  },
  {
    label: "Analytics",
    destination: "/analytics",
  },
  {
    label: "Reports",
    destination: "/reports",
  },
  {
    label: "Sitemaps",
    destination: "/sitemaps",
  },
  {
    label: "Settings",
    destination: "/settings",
  },
  {
    label: "Subscription Plans",
    destination: "/subscription",
  },
  {
    label: "AI Content Optimizer",
    destination: "/credit-bundles",
  },
];

export const EMPTYSTATE_IMAGES = {
  default: "/img/svg/emptystate-default.svg",
  products: "/img/svg/emptystate-products.svg",
  pages: "/img/svg/emptystate-pages.svg",
  blogPosts: "/img/svg/emptystate-blogs.svg",
  imageOptimizer: "/img/svg/emptystate-images.svg",
};

export const MAINTENANCE_ILLUSTRATION = "/img/svg/maintenance.svg";

export const SEO_TAGS = [
  {
    key: "product_title",
    title: "Product title",
  },
  {
    key: "product_description",
    title: "Product description",
  },
  {
    key: "product_type",
    title: "Product type",
  },
  {
    key: "product_vendor",
    title: "Product vendor",
  },
  {
    key: "shop_name",
    title: "Shop name",
  },
  {
    key: "focus_keyword",
    title: "Focus keyword",
  },
];

export const HTML_SITEMAP_URL = process.env?.FRONTEND_SITEMAP_URL || `/a/sitemap`;

export const SUBSCRIPTION_ADDONS = [
  {
    key: IMAGE_OPTIMIZER,
    title: "Image Optimizer",
    icon: ImageWithTextOverlayIcon,
    type: subscriptionAddonInterval.MONTHLY,
  },
  {
    key: AI_OPTIMIZER,
    title: "AI Content Optimizer",
    icon: MagicIcon,
    type: subscriptionAddonInterval.CREDIT,
  },
];

export const IMAGE_COMPRESSION_TYPES = [
  { label: "None", value: "none", hint: "" },
  {
    label: "Lossless",
    value: "lossless",
    hint: "Significant reduction in size with minimal sacrifice to image quality",
  },
  { label: "Balanced", value: "balanced", hint: "Moderate reduction size with zero sacrifice to image quality" },
  { label: "Lossy", value: "lossy", hint: "High reduction in size at the expense of image quality" },
];

export const IMAGE_OPTIMIZER_OPTIONS = {
  COMPRESSION_TYPES: [
    { label: "None", value: "none", hint: "" },
    {
      label: "Lossless",
      value: "lossless",
      hint: "Significant reduction in size with minimal sacrifice to image quality",
    },
    { label: "Balanced", value: "balanced", hint: "Moderate reduction size with zero sacrifice to image quality" },
    { label: "Lossy", value: "lossy", hint: "High reduction in size at the expense of image quality" },
  ],
  FORMATS: [
    { label: "None", value: "none", hint: "" },
    // { label: "WebP", value: "webp", hint: "" },
    { label: "JPEG", value: "jpeg", hint: "" },
  ],
  RESIZES: [
    { label: "Do not resize", value: "none", hint: "" },
    { label: "4000px", value: "4000", hint: "" },
    { label: "2048px (Recommended by Shopify)", value: "2048", hint: "" },
    { label: "1600px", value: "1600", hint: "" },
  ],
};

export const PAGE_TEMPLATES = {
  DASHBOARD: "DASHBOARD",
  FIX: "FIX",
  SETTINGS: "SETTINGS",
};

export const whatsNewContents = [
  {
    key: "multi-language",
    label: "Optimize Translated Products with Multilingual SEO",
    content:
      "Get Multilingual SEO Support for the translated products of your Shopify store and easily optimize them for SEO.",
    button: {
      url: "/settings/multi-language",
      content: "Check it out",
    },
    image: "https://cdn.storeseo.com/whats-new/multi-language.png",
  },
  {
    key: "local-seo",
    label: "Boost the Visibility of Your Business in Local Search",
    content:
      "Optimize your Google Business Profile with accurate information and engaging photos to enhance your local search presence.",
    button: {
      url: "/local-seo",
      content: "Check it out",
    },
    image: "https://cdn.storeseo.com/whats-new/local-seo.png",
  },
  {
    key: "ai-optimizer",
    label: "AI Content Optimization in a Few Clicks",
    content:
      "Try our AI Content Optimizer to make your meta title, meta description, image alt text and tags SEO-friendly with just a single click.",
    button: {
      url: "/credit-bundles",
      content: "Check it out",
    },
    image: "https://cdn.storeseo.com/whats-new/ai-optimizer.png",
  },
  {
    key: "image-optimizer",
    label: "Bulk Image Optimize for Smooth Workflow",
    content:
      "Optimize your store images instantly with Image Optimizer & boost your page speed along with bulk image optimization.",
    button: {
      url: "/image-optimizer",
      content: "Check it out",
    },
    image: "https://cdn.storeseo.com/whats-new/image-optimizer.png",
  },
  {
    key: "html-sitemap",
    label: "Get HTML Sitemap For Your Store",
    content:
      "Generate an HTML Sitemap page instantly on your store & help your visitors find all of your pages in one place.",
    button: {
      url: "/sitemaps/html-sitemap",
      content: "Check it out",
    },
    image: "https://cdn.storeseo.com/whats-new/html-sitemap.png",
  },
];

export const appsByStoreware = [
  {
    key: "betterdocs",
    image: "https://cdn.storeseo.com/own-apps/betterdcos.png",
    title: "BetterDocs - Knowledge Base Tool",
    content:
      "Create docs & FAQs for your Shopify store powered by AI & Live Chat and optimize all the created docs for SEO using StoreSEO.",
    features: [
      "Pick from 2 engaging design templates",
      "Use the power of AI to write docs & FAQs instantly",
      "Utilize features like Live Chat & Instant Answer",
      "Get access to insightful data with Advanced Analytics",
    ],
    button: {
      label: "Try for FREE",
      url: "https://storeseo.com/go/get-betterdocs",
    },
  },
  {
    key: "trustsync",
    image: "https://cdn.storeseo.com/own-apps/trustsync.png",
    title: "TrustSync - Review Collection App",
    content:
      "Boost your business reputation by collecting reviews for Google Review, TrustPilot, Etsy & more while filtering out negative ratings.",
    features: [
      "Collect customers’ reviews via review collection emails",
      "Auto-publish reviews on popular platforms",
      "Review Settings & Email Configurations",
      "Manage Negative Feedback while filtering out low ratings",
    ],
    button: {
      label: "Try for FREE",
      url: "https://storeseo.com/go/get-trustsync",
    },
  },
  {
    key: "storefaq",
    image: "https://cdn.storeseo.com/own-apps/storefaq.png",
    title: "StoreFAQ - Product FAQ Builder",
    content:
      "Create FAQ pages or build individual product FAQs with AI to reduce support tickets and improve the customer experience.",
    features: [
      "Use the advanced AI Autowrite feature for writing FAQs instantly",
      "Design the FAQ page with various customizations",
      "Drag & drop to add the StoreFAQ App block anywhere on a page",
      "Export or import FAQs without any hassle",
    ],
    button: {
      label: "Try for FREE",
      url: "https://storeseo.com/go/storefaq",
    },
  },
];

export const SETTINGS_MENU = [
  // General settings
  { key: "general-setting", url: "/settings", title: "General Settings", isLocked: false, icon: AdjustIcon },
  {
    key: "app-embed",
    url: "/settings/app-embed",
    title: "App Embed Settings",
    isLocked: false,
    icon: AppExtensionIcon,
  },
  {
    key: "email-notification",
    url: "/settings/email-notification",
    title: "Email Notification",
    isLocked: false,
    icon: EmailIcon,
  },

  // Advanced features
  {
    key: "image-optimizer",
    url: "/settings/image-optimizer",
    title: "Image Optimizer",
    isLocked: true,
    icon: ImageMagicIcon,
  },
  {
    key: "auto-ai-optimization",
    url: "/settings/auto-ai-optimization",
    title: "Auto AI Optimization",
    isLocked: false,
    icon: MagicIcon,
  },

  // SEO features
  {
    key: "bulk-seo",
    url: "/settings/bulk-seo-update",
    title: "Bulk SEO Optimization",
    isLocked: false,
    icon: ShopcodesIcon,
  },
  {
    key: "seo-migrator",
    url: "/settings/seo-migrator",
    title: "SEO Migrator",
    isLocked: false,
    icon: ArrowsOutHorizontalIcon,
  },
  { key: "html-sitemap", url: "/settings/html-sitemap", title: "HTML Sitemap", isLocked: false, icon: DomainIcon },

  // Google integrations
  {
    key: "google-integration",
    url: "/settings/google-integration",
    title: "Google Integration",
    isLocked: true,
    icon: ConnectIcon,
  },
  {
    key: "google-analytics",
    url: "/settings/google-analytics",
    title: "Google Analytics",
    isLocked: true,
    icon: ChartVerticalIcon,
  },
  {
    key: "google-sitemap",
    url: "/settings/google-sitemap",
    title: "Google Sitemaps",
    isLocked: true,
    icon: CursorOptionIcon,
  },
  {
    key: "multi-language",
    url: "/settings/multi-language",
    title: "Multilingual SEO",
    isLocked: true,
    icon: LanguageIcon,
  },
  {
    key: "backup-restore",
    url: "/settings/backup-restore",
    title: "Backup & Restore",
    isLocked: false,
    icon: DnsSettingsIcon,
  },
];
