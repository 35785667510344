import { ActionList, Box, Button, Popover } from "@shopify/polaris";
import { GlobeIcon } from "@shopify/polaris-icons";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { languages } from "../../config";

const options = languages.map((l) => ({ ...l, label: `${l?.label} (${l.value.toUpperCase()})` }));

/**
 * @param {import("@shopify/polaris").ButtonProps["variant"]} buttonVariant - The variant of the button.
 * @returns {React.ReactNode}
 */
const LanguageSwitcher = ({ buttonVariant }) => {
  const { i18n, t } = useTranslation();
  const [active, setActive] = useState(false);

  const selected =
    options.find((l) => l.value === i18n.language) || options.find((l) => l.value === i18n.options.fallbackLng);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const changeLanguage = useCallback(
    (selectedOption) => {
      i18n.changeLanguage(selectedOption?.value);
      toggleActive();
    },
    [i18n, toggleActive]
  );

  const activator = (
    <Box
      style={{
        display: buttonVariant === "monochromePlain" ? "flex" : "inline-flex",
        alignItems: "center",
      }}
    >
      <Button
        onClick={toggleActive}
        disclosure={active ? "down" : "up"}
        variant={buttonVariant}
        icon={GlobeIcon}
      >
        {selected?.label}
      </Button>
    </Box>
  );

  return (
    <Popover
      active={active}
      activator={activator}
      autofocusTarget="first-node"
      onClose={toggleActive}
    >
      <ActionList
        actionRole="menuitem"
        items={options.map((option) => ({
          active: option?.value === i18n.language,
          content: option?.label,
          onAction: () => changeLanguage(option),
        }))}
      />
    </Popover>
  );
};

export default LanguageSwitcher;
