import { Banner, Page, Text, TextField } from "@shopify/polaris";
import { lazy } from "react";
import { useTranslation } from "react-i18next";
import { Route, useRouteError } from "react-router-dom";
import NotFound from "./pages/NotFound.jsx";

const DefaultLayout = lazy(() => import("./layouts/DefaultLayout"));
const SettingsLayout = lazy(() => import("./layouts/SettingsLayout"));
const LocalSeoLayout = lazy(() => import("./layouts/LocalSeoLayout"));
const SitemapsLayout = lazy(() => import("./layouts/SitemapsLayout"));

/**
 * File-based routing.
 * @desc File-based routing that uses React Router under the hood.
 * To create a new route create a new .jsx file in `/pages` with a default export.
 *
 * Some examples:
 * * `/pages/index.jsx` matches `/`
 * * `/pages/blog/[id].jsx` matches `/blog/123`
 * * `/pages/[...catchAll].jsx` matches any URL not explicitly matched
 *
 * @param {object} pages value of import.meta.globEager(). See https://vitejs.dev/guide/features.html#glob-import
 *
 * @return {Routes} `<Routes/>` from React Router, with a `<Route/>` for each file in `pages`
 */
export default function Routes({ pages }) {
  const routes = useRoutes(pages);
  const routeComponents = routes.map(({ path, component: Component, layout: Layout }) => {
    return (
      <Route
        key={path}
        element={<Layout />}
        hasErrorBoundary
        errorElement={<ErrorBoundary />}
      >
        <Route
          path={path}
          element={<Component />}
        />
      </Route>
    );
  });

  return (
    <>
      {routeComponents}
      <Route
        path="*"
        element={<NotFound />}
      />
    </>
  );
}

function useRoutes(pages) {
  const routes = Object.keys(pages)
    .map((key) => {
      let path = key
        .replace("./pages", "")
        .replace(/\.(t|j)sx?$/, "")
        /**
         * Replace /index with /
         */
        .replace(/\/index$/i, "/")
        /**
         * Only lowercase the first letter. This allows the developer to use camelCase
         * dynamic paths while ensuring their standard routes are normalized to lowercase.
         */
        .replace(/\b[A-Z]/, (firstLetter) => firstLetter.toLowerCase())
        /**
         * Convert /[handle].jsx and /[...handle].jsx to /:handle.jsx for react-router-dom
         */
        .replace(/\[(?:[.]{3})?(\w+?)\]/g, (_match, param) => `:${param}`);

      if (path.endsWith("/") && path !== "/") {
        path = path.substring(0, path.length - 1);
      }

      let layout = DefaultLayout;

      if (path.startsWith("/settings")) {
        layout = SettingsLayout;
      }

      if (path.startsWith("/local-seo")) {
        layout = LocalSeoLayout;
      }

      if (path.startsWith("/sitemaps") && !path.endsWith("html-sitemap")) {
        layout = SitemapsLayout;
      }

      return {
        path,
        component: lazy(pages[key]),
        layout,
      };
    })
    .filter((route) => route.component);

  return routes;
}

function ErrorBoundary() {
  let error = useRouteError();
  const { t } = useTranslation();

  return (
    <Page>
      <Banner
        tone="critical"
        title={`${error.name}: ${error.message}`}
        // action={{
        //   content: "Refresh",
        //   onAction() {
        //     setLoading(true);
        //     window.top.location.reload();
        //   },
        //   loading,
        // }}
      >
        <TextField
          type="text"
          value={error.stack}
          multiline={4}
          monospaced
          readOnly
          variant="borderless"
        />

        <Text
          as="p"
          variant="bodySm"
          tone="subdued"
        >
          {t("Try refreshing the page or clearing your browser cache to resolve this issue.")}
        </Text>
      </Banner>
    </Page>
  );
}
