//@ts-check
import { BlockStack, Box, Icon, InlineStack, Popover, Text } from "@shopify/polaris";
import { InfoIcon } from "@shopify/polaris-icons";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { statsSchemaItem } from "storeseo-schema/dashboard-stats";

/**
 *
 * @param {{resourceType: keyof typeof import("storeseo-enums/resourceType"), item: import("yup").InferType<typeof statsSchemaItem>}} props
 * @returns
 */
export default function StatisticCardDetailsPopover(props) {
  const { resourceType, item } = props;
  const { t } = useTranslation();
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);

  const activator = (
    <span
      onClick={togglePopoverActive}
      style={{ cursor: "pointer" }}
    >
      <Icon
        source={InfoIcon}
        tone="base"
      />
    </span>
  );

  const totalResourceTitle =
    resourceType === "ARTICLE"
      ? "blog posts"
      : resourceType === "PAGE"
      ? "pages"
      : resourceType === "PRODUCT"
      ? "products"
      : resourceType.toLowerCase();

  return (
    <Popover
      active={popoverActive}
      activator={activator}
      onClose={togglePopoverActive}
      autofocusTarget="first-node"
    >
      <Popover.Pane fixed>
        <Box
          minWidth="212px"
          padding="300"
        >
          {item && (
            <BlockStack gap="300">
              <PopoverItem
                title={t(`Total ${totalResourceTitle}`)}
                value={item.total}
              />
              <PopoverItem
                title={t("Overall score")}
                value={`${item.overallScore}%`}
                tone="success"
              />
              <PopoverItem
                title={t("Optimized")}
                value={`${item.optimized}%`}
                tone="success"
              />
              <PopoverItem
                title={t("Not optimized")}
                value={`${item.notOptimized}%`}
                tone="critical"
              />
            </BlockStack>
          )}
        </Box>
      </Popover.Pane>
    </Popover>
  );
}

/**
 *
 * @param {{title: string, value: string|number, tone?: import("@shopify/polaris").TextProps["tone"]}} param0
 * @returns
 */
const PopoverItem = ({ title, value, tone = "base" }) => {
  return (
    <InlineStack
      align="space-between"
      blockAlign="center"
      gap="100"
    >
      <Text
        as="p"
        variant="bodyMd"
      >
        {title}
      </Text>
      <Text
        as="p"
        variant="bodyMd"
        tone={tone}
        fontWeight="medium"
      >
        {value}
      </Text>
    </InlineStack>
  );
};
