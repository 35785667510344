import { Badge, BlockStack, Button, Card, Grid, InlineStack, Text, Tooltip } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import resourceType from "storeseo-enums/resourceType";
import { useAppQuery, useShopApi } from "../../hooks";
import { useStoreSeo } from "../../providers/StoreSeoProvider";
import queryKeys from "../../utility/queryKeys";
import StatisticCard from "./StatisticCard";

let statsCards = [
  {
    key: "productStats",
    title: "Products",
    description: "Total products",
    resourceType: resourceType.PRODUCT,
  },
  {
    key: "collectionStats",
    title: "Collections",
    description: "Total collections",
    resourceType: resourceType.COLLECTION,
  },
  {
    key: "pageStats",
    title: "Pages",
    description: "Total pages",
    resourceType: resourceType.PAGE,
  },
  {
    key: "articleStats",
    title: "Blog Posts",
    description: "Total blog posts",
    resourceType: resourceType.ARTICLE,
  },
];

const StatisticsRow = () => {
  const shopApi = useShopApi();
  const { doManualRefresh } = useStoreSeo();
  const { t } = useTranslation();

  const {
    data = {},
    refetch,
    isFetching,
  } = useAppQuery({
    queryKey: [queryKeys.DASHBOARD_STATS],
    queryFn: shopApi.getDashboardStats,
    reactQueryOptions: {
      staleTime: 0,
    },
  });

  if (doManualRefresh) refetch();

  const { isVerified, isPremium } = useSelector((state) => state.user);

  return (
    <BlockStack gap="400">
      <Card>
        <BlockStack gap="400">
          <InlineStack
            gap="200"
            align="space-between"
            blockAlign="center"
          >
            <InlineStack gap="200">
              <Text
                variant="headingLg"
                as="h3"
              >
                {t("Store Details")}
              </Text>

              {!isPremium &&
                (isVerified ? (
                  <Badge tone="success">{t("Verified")}</Badge>
                ) : (
                  <Tooltip
                    preferredPosition="above"
                    persistOnClick
                    content={t("Schedule a call with us to verify your store and double your product limit")}
                  >
                    <Badge tone="attention">{t("Unverified")}</Badge>
                  </Tooltip>
                ))}
            </InlineStack>

            <Button url="/reports">{t("View reports")}</Button>
          </InlineStack>
          <Grid columns={{ xs: 1, sm: 2, md: 2, lg: 4, xl: 4 }}>
            {statsCards.map(({ key, ...rest }) => (
              <Grid.Cell key={key}>
                <StatisticCard
                  isLoading={isFetching}
                  item={data[key] ?? null}
                  {...rest}
                />
              </Grid.Cell>
            ))}
          </Grid>
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default StatisticsRow;
