import { Banner, BlockStack, Button, Card, InlineStack, ProgressBar, Text } from "@shopify/polaris";
import { AlertCircleIcon } from "@shopify/polaris-icons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useUserAddon from "../../hooks/useUserAddon";
import LearnMoreButton from "../common/LearnMoreButton";

/**
 * @typedef {object} LearnMoreButtonType
 * @property {string} title
 * @property {string} url
 */

/**
 * @typedef {object} ActionType
 * @property {string} content
 * @property {string} url
 */

/**
 * @param {{title: string, group: string, hiddenBannerKey: string, learnMoreButton: LearnMoreButtonType, action: ActionType}}
 * @returns
 */
const UsageLimitCard = ({ title, group, learnMoreButton = undefined, action = undefined, imageOptimizeSettings }) => {
  const { t } = useTranslation();
  const { addons } = useUserAddon();
  // const shopApi = useShopApi();
  // const dispatch = useDispatch();

  const user = useSelector((s) => s.user);

  const addon = addons.find((a) => a.group === group);

  if (!addon?.isActive) return null;

  const daysSinceSubscription = moment().diff(moment(user.subscribedAt), "d");
  const daysTillReset = 30 - (daysSinceSubscription % 30);
  const usagePercentage = Math.round((addon.usageCount * 100) / addon.usageLimit);

  return (
    <Card>
      <BlockStack gap={"300"}>
        <BlockStack>
          <InlineStack
            gap={"200"}
            align="space-between"
            blockAlign="center"
          >
            <InlineStack
              blockAlign="center"
              gap="100"
            >
              <Text variant="headingSm">{t(title)}</Text>

              {learnMoreButton && (
                <LearnMoreButton
                  title={t(learnMoreButton.title)}
                  url={learnMoreButton.url}
                />
              )}
            </InlineStack>
            {action && (
              <Button
                size="micro"
                url={action.url}
              >
                {t(action.content)}
              </Button>
            )}
          </InlineStack>

          <Text
            as="p"
            tone="subdued"
          >
            ({t("Current plan")}: {addon.usageLimit}
            {!addon.isFree && ` ${t(addon.limitLabel)}${addon.isMonthly ? t("/month") : ""}`})
          </Text>
        </BlockStack>

        <BlockStack gap={"150"}>
          <InlineStack
            gap={"200"}
            align="space-between"
          >
            <Text tone="subdued">
              {Number(addon.usageCount).toFixed(2)}/{Number(addon.usageLimit).toFixed(0)} {t(addon.limitLabel)}
            </Text>
            {!addon.isFree && addon.isMonthly && (
              <Text>{t("Monthly usage resets in {{DAYS_TILL_RESET}} days", { DAYS_TILL_RESET: daysTillReset })}</Text>
            )}
          </InlineStack>
          <ProgressBar
            size="small"
            progress={usagePercentage}
            tone={usagePercentage < 75 ? "success" : "critical"}
          />
        </BlockStack>
        {imageOptimizeSettings?.autoOptimization && (
          <Banner
            icon={AlertCircleIcon}
            tone="warning"
          >
            <Text as="p">
              {t("Enabling this feature will automatically use the available limit on your Image Optimizer plan")}
            </Text>
          </Banner>
        )}
      </BlockStack>
    </Card>
  );
};

export default UsageLimitCard;
