import { BlockStack, Box, Button, Card, Icon, IndexTable, InlineStack, Page, Text } from "@shopify/polaris";
import React from "react";
import SkeletonLoader from "./SkeletonLoader";
import { FilterIcon, SearchIcon } from "@shopify/polaris-icons";

export const ImageOptimizerLoader = () => {
  return (
    <Page
      fullWidth
      title="Image Optimizer"
      backAction={{ url: "#" }}
    >
      <BlockStack gap="400">
        <Card>
          <BlockStack gap="300">
            <InlineStack
              align="space-between"
              blockAlign="center"
            >
              <BlockStack gap="200">
                <Text>Image Optimizer</Text>
                <SkeletonLoader style={{ width: 200, height: 10 }} />
              </BlockStack>
              <Button disabled>Increase limit</Button>
            </InlineStack>
            <BlockStack>
              <InlineStack align="space-between">
                <SkeletonLoader style={{ width: 100, height: 10 }} />
                <SkeletonLoader style={{ width: 200, height: 10 }} />
              </InlineStack>
              <SkeletonLoader
                style={{ height: 8, width: "100%" }}
                lineHeight={0}
              />
            </BlockStack>
          </BlockStack>
        </Card>

        <Card padding={0}>
          <BlockStack>
            <Box padding="400">
              <InlineStack align="end">
                <SkeletonLoader
                  style={{ height: 28, width: 60 }}
                  lineHeight={0}
                />
              </InlineStack>
            </Box>
            <IndexTable
              resourceName={{ singular: "order", plural: "orders" }}
              itemCount={20}
              headings={[
                { title: "" },
                { title: "File name" },
                { title: "Status" },
                { title: "File size" },
                { title: "Product" },
                { title: "Action" },
              ]}
            >
              {[...Array(20)].map((_, index) => (
                <IndexTable.Row
                  key={index}
                  position={index}
                >
                  <IndexTable.Cell className="width-80">
                    <SkeletonLoader
                      style={{ width: 40, height: 40 }}
                      lineHeight={0}
                    />
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <SkeletonLoader
                      style={{ width: 160, height: 10 }}
                      lineHeight={0}
                    />
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <SkeletonLoader
                      style={{ width: 60, height: 10 }}
                      lineHeight={0}
                    />
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <SkeletonLoader
                      style={{ width: 60, height: 10 }}
                      lineHeight={0}
                    />
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <SkeletonLoader
                      style={{ width: 60, height: 10 }}
                      lineHeight={0}
                    />
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <SkeletonLoader
                      style={{ width: 60, height: 10 }}
                      lineHeight={0}
                    />
                  </IndexTable.Cell>
                </IndexTable.Row>
              ))}
            </IndexTable>
          </BlockStack>
        </Card>
      </BlockStack>
    </Page>
  );
};
