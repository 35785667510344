import { useTranslation } from "react-i18next";
import bpoOptions from "storeseo-enums/bpoOptions";
import toastMessages from "storeseo-enums/toastMessages";
import { statsSchema } from "storeseo-schema/dashboard-stats";
import {
  DASHBOARD_STATS_API_ENDPOINT,
  GOOGLE_INTEGRATION_API_ENDPOINT,
  HTMLS_SITEMAP_SETUP_INFO_ENDPOINT,
  HTML_SITEMAP_API_ENDPOING,
  INDUSTRY_API_ENDPOINT,
  PAYMENT_HISTORY_ENDPOINT,
  SHOP_API_ENDPOINT,
  STATISTICS_REPORT_API_ENDPOINT,
} from "../../utility/apiRoutes";
import { showNotification } from "../../utility/helpers";
import { useApiClient } from "../useApiClient";

export const useShopApi = () => {
  const { t } = useTranslation();
  const apiClient = useApiClient();

  const getShop = async () => {
    try {
      const { shop = {} } = await apiClient(SHOP_API_ENDPOINT);
      return shop;
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error fetching shop: ", err);
      return null;
    }
  };

  const getPaginatedPaymentHistory = async (query) => {
    try {
      const { transactions, pagination } = await apiClient(PAYMENT_HISTORY_ENDPOINT, {
        query,
      });

      return { transactions, pagination };
    } catch (err) {
      // processAndShowErrorNotification(err);
      return {};
    }
  };

  const uploadShopLogo = async (logoImage) => {
    try {
      const formData = new FormData();
      if (logoImage) formData.append("logoImage", logoImage, logoImage?.name);

      const { message, uploadPath, logoURL } = await apiClient(`${SHOP_API_ENDPOINT}/logo`, {
        method: "PUT",
        body: formData,
        headers: {},
      });

      showNotification({ type: "success", message: t(message) });

      return { updateSuccessful: true, message, uploadPath, logoURL };
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error updating local seo data: ", err);
      throw new Error("Something went wrong!");
    }
  };

  const getGoogleServiceJsonFile = async (file) => {
    try {
      const { fileName, config } = await apiClient(`${SHOP_API_ENDPOINT}/google-service-json`);

      return { fileName, config };
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error updating local seo data: ", err);
      throw new Error("Something went wrong!");
    }
  };

  const uploadGoogleServiceJsonFile = async (file) => {
    try {
      const formData = new FormData();
      if (file) formData.append("serviceJsonFile", file, file?.name);

      const { message, uploadPath, logoURL } = await apiClient(`${SHOP_API_ENDPOINT}/google-service-json`, {
        method: "PUT",
        body: formData,
        headers: {},
      });

      showNotification({ type: "success", message: t(message) });

      return { updateSuccessful: true, message, uploadPath, logoURL };
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error updating local seo data: ", err);
      throw new Error("Something went wrong!");
    }
  };

  const getGoogleIntegrationInfo = async () => {
    try {
      const { integrationInfo, authURLs = {} } = await apiClient(GOOGLE_INTEGRATION_API_ENDPOINT);

      return { integrationInfo, authURLs };
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error fetching google integration info: ", err);
      return null;
    }
  };

  /**
   *
   * @param {{[string]: *}} data
   * @returns {number}
   */
  const updateGoogleIntegrationInfo = async (data) => {
    try {
      const { message, integrationInfo } = await apiClient(GOOGLE_INTEGRATION_API_ENDPOINT, {
        method: "PUT",
        body: JSON.stringify(data),
      });

      showNotification({ type: "success", message: t(message) });

      return integrationInfo;
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error updating google integration info: ", err);
      throw new Error("Something went wrong!");
    }
  };

  const resetGoogleIntegrationInfo = async () => {
    try {
      const { message } = await apiClient(`${GOOGLE_INTEGRATION_API_ENDPOINT}/reset`, {
        method: "PUT",
      });
      showNotification({ type: "success", message: t(message) });
      return true;
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error resetting google integration info: ", err);
      throw new Error("Something went wrong!");
    }
  };

  /**
   *
   * @param {string} analyticsPropertyId
   * @returns {boolean}
   */
  const verifyGoogleAnalyticsPropertyId = async (analyticsPropertyId) => {
    try {
      const { isValid } = await apiClient(`${GOOGLE_INTEGRATION_API_ENDPOINT}/verify-analytics-property-id`, {
        method: "POST",
        body: JSON.stringify({ analyticsPropertyId }),
      });

      // if (!isValid) showNotification({ message: "Please enter a valid property ID!", type: "warning" });

      return isValid;
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error updating google integration info: ", err);
      return false;
    }
  };

  const updateGoogleIndexingStatus = async () => {
    try {
      const { message } = await apiClient("/setting/google-indexing", {
        method: "PUT",
        body: JSON.stringify({ status: true }),
      });

      showNotification({ type: "success", message: t(message) });
    } catch (err) {
      // processAndShowErrorNotification(err);
    }
  };

  const getIndustries = async () => {
    try {
      const { industries } = await apiClient(INDUSTRY_API_ENDPOINT);
      return industries;
    } catch (err) {
      // processAndShowErrorNotification(err);
      return [];
    }
  };

  const hideAdvertiseTemporarily = async () => {
    try {
      return await apiClient("/settings/hide-ad", { method: "PUT" });
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error hiding advertise via API. Error: ", err);
      return null;
    }
  };

  const hideBetterDocsPromoTemporarily = async () => {
    try {
      const data = await apiClient("/settings/hide-betterdocs-promo", {
        method: "PUT",
      });

      return data;
    } catch (err) {
      console.log("Error hiding betterdocs promo. Error: ", err);
      return null;
    }
  };

  const hideBanner = async (bannerKey, expiry = null) => {
    try {
      return await apiClient("/settings/hide-banner", {
        method: "PUT",
        body: JSON.stringify({
          key: bannerKey,
          value: 1,
          expiry,
        }),
      });
    } catch (err) {
      console.log("Error hiding banner", bannerKey, err);
      return null;
    }
  };

  const migrateDataFromAnotherApp = async (selectedApp) => {
    try {
      const { message, selectedAppTitle } = await apiClient(`${SHOP_API_ENDPOINT}/migrate-data`, {
        method: "POST",
        body: JSON.stringify({ selectedApp }),
      });

      showNotification({
        // message: i18n.t("Data migration from") + " " + selectedAppTitle + " " + t("is in progress!"),
        message: t(toastMessages.success.DATA_MIGRATION_STARTED),
        type: "success",
      });
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error migrating data from another app via API. Error: ", err);
    }
  };

  /**
   *
   * @returns {Promise<import("yup").InferType<typeof statsSchema>>}
   */
  const getDashboardStats = async () => apiClient(DASHBOARD_STATS_API_ENDPOINT);

  const getStatisticsReport = async () => apiClient(STATISTICS_REPORT_API_ENDPOINT);

  const submitSitemapsToGoogle = async () => {
    try {
      const { message, shop } = await apiClient("/sitemaps/submit-to-google");
      showNotification({ type: "success", message: t(message) });

      const { is_sitemap_submitted, sitemap_submitted_at, google_integration_info } = shop;
      return { is_sitemap_submitted, sitemap_submitted_at, google_integration_info };
    } catch (err) {
      // processAndShowErrorNotification(err);
      throw new Error("Something went wrong!");
    }
  };

  const updateSeoSetting = async (data) => {
    try {
      const { message, setting } = await apiClient("/setting/seo-setting", {
        method: "POST",
        body: JSON.stringify(data),
      });

      // showNotification({ type: "success", message });
      return { message: t(message), setting };
    } catch (err) {
      // processAndShowErrorNotification(err);
      throw new Error("Something went wrong!");
    }
  };

  const startAutoOptimization = async (bpoOption = bpoOptions.NON_OPTIMIZED_PRODUCTS) => {
    try {
      const { message } = await apiClient("/onboard/optimization", {
        method: "POST",
        body: JSON.stringify({ bpoOption }),
      });
      showNotification({ type: "success", message: t(message) });
    } catch (err) {
      // processAndShowErrorNotification(err);
      throw new Error("Something went wrong!");
    }
  };

  const getHtmlSitemapSetupInfo = async () => {
    try {
      const data = await apiClient(HTMLS_SITEMAP_SETUP_INFO_ENDPOINT);
      return data;
    } catch (err) {
      throw new Error("Something went wrong.");
    }
  };

  const updateHtmlSitemapSetupInfo = async (updatedData) => {
    const { data, message } = await apiClient(HTMLS_SITEMAP_SETUP_INFO_ENDPOINT, {
      method: "PUT",
      body: JSON.stringify(updatedData),
    });

    showNotification({ message: t(message) });
    return data;
  };

  const getHtmlSitemapSetting = async () => {
    try {
      const { sitemapSetting } = await apiClient(HTML_SITEMAP_API_ENDPOING);
      return sitemapSetting;
    } catch (err) {
      console.error(err);
    }
  };

  const updateHtmlSitemapSetting = async (data) => {
    try {
      const { message } = await apiClient(HTML_SITEMAP_API_ENDPOING, {
        method: "POST",
        body: JSON.stringify(data),
      });
      showNotification({ type: "success", message: t(message) });
    } catch (err) {
      console.error(err);
      // throw new Error("Something went wrong!");
    }
  };

  const getImageOptimizerSetting = async () => {
    try {
      const { data } = await apiClient("/setting/image-optimizer");
      return data;
    } catch (err) {
      throw new Error("Something went wrong.");
    }
  };

  const updateImageOptimizerSetting = async (payload) => {
    try {
      const { message, data } = await apiClient("/setting/image-optimizer", {
        method: "PUT",
        body: JSON.stringify(payload),
      });

      console.log("data: ", data);

      showNotification({ type: "success", message: t(message) });
      return { message: t(message), setting: data };
    } catch (err) {
      // processAndShowErrorNotification(err);
      // throw new Error("Something went wrong!");
    }
  };

  const toggleAutoImageOptimization = async (autoOptimization) => {
    const { data, message } = await apiClient("/setting/image-optimizer/auto-optimization", {
      method: "PUT",
      body: JSON.stringify({ autoOptimization }),
    });

    showNotification({ type: "success", message: t(message) });
    return data.autoOptimization;
  };

  const getMultiLanguageSetting = async () => {
    try {
      const { setting } = await apiClient("/setting/multi-language");
      return setting;
    } catch (err) {
      throw new Error("Something went wrong");
    }
  };

  const toggleMultiLanguageSupport = async (enabled) => {
    const { setting, message } = await apiClient("/setting/multi-language/toggle", {
      method: "PUT",
      body: JSON.stringify({ enabled }),
    });

    showNotification({ type: "success", message: t(message) });
    return setting.enabled;
  };

  const syncMulitLanguageData = async (locale) => {
    const { success, message } = await apiClient("/setting/multi-language/sync", {
      method: "POST",
      body: JSON.stringify({ locale }),
    });

    showNotification({ type: "success", message: t(message) });
    return success;
  };

  return {
    getShop,
    getIndustries,
    getPaginatedPaymentHistory,
    getDashboardStats,
    getStatisticsReport,
    hideBetterDocsPromoTemporarily,
    submitSitemapsToGoogle,
    updateSeoSetting,
    startAutoOptimization,
    migrateDataFromAnotherApp,
    getGoogleIntegrationInfo,
    updateGoogleIntegrationInfo,
    resetGoogleIntegrationInfo,
    verifyGoogleAnalyticsPropertyId,
    updateGoogleIndexingStatus,
    uploadShopLogo,
    getGoogleServiceJsonFile,
    uploadGoogleServiceJsonFile,
    hideAdvertiseTemporarily,
    hideBanner,
    getHtmlSitemapSetting,
    updateHtmlSitemapSetting,
    getHtmlSitemapSetupInfo,
    updateHtmlSitemapSetupInfo,

    getImageOptimizerSetting,
    updateImageOptimizerSetting,
    toggleAutoImageOptimization,

    getMultiLanguageSetting,
    toggleMultiLanguageSupport,
    syncMulitLanguageData,
  };
};
