import { Text } from "@shopify/polaris";
import classNames from "classnames";
import { useEffect, useState } from "react";

import { useChartScoreVariant } from "../../hooks/useChartScoreVariant.js";
import { useScoreColor } from "../../hooks/useScoreColor.js";

/**
 * @typedef {Object} RadialChartProps
 * @property {number} [radius=80] - The radius of the chart circle
 * @property {number} [score=0] - The score to display (0-100)
 * @property {number} [strokeWidth=20] - The width of the progress stroke
 * @property {number} [dimension=50] - The dimension of the chart container
 * @property {string} [chartTitle=""] - The title to display below the score
 * @property {("small"|"medium"|"large")} [size="small"] - The size variant of the chart
 * @property {("success"|"warning"|"critical"|null)} [color=null] - The color variant of the chart
 * @property {boolean} [showPercent=false] - Whether to show the percentage symbol
 */

/**
 *
 * @param {RadialChartProps} param0
 * @returns
 */

const RadialChart = ({
  radius = 80,
  score = 0,
  strokeWidth = 20,
  dimension = 50,
  chartTitle = "",
  size = "small",
  color = null,
  showPercent = false,
}) => {
  const [setStrokeLength, setSetStrokeLength] = useState(false);
  const background = "#F2F7FE";

  useEffect(() => {
    // For initial animation
    setTimeout(() => {
      setSetStrokeLength(true);
    });
  }, []);

  const circleRadius = Math.min(radius, 85);
  const circumference = 2 * 3.14 * circleRadius;
  const strokeLength = setStrokeLength ? (circumference / 100) * score : 0;
  const bgStrokeWidth = Math.floor(strokeWidth * 1.2);

  const scoreColor = useScoreColor(score, color);
  const variant = useChartScoreVariant(size);

  return (
    <div
      className={classNames({
        "no-progress": strokeLength === 0,
      })}
      style={{ position: "relative", height: `${dimension}px` }}
    >
      <svg
        viewBox="0 0 180 180"
        width={dimension}
        height={dimension}
      >
        <circle
          stroke={background}
          strokeWidth={bgStrokeWidth}
          fill="none"
          cx="90"
          cy="90"
          r={circleRadius}
        />
        <circle
          stroke={`${scoreColor}`}
          strokeWidth={strokeWidth}
          strokeDasharray={`${strokeLength},${circumference}`}
          strokeLinecap="round"
          fill="none"
          cx="90"
          cy="90"
          r={circleRadius}
          style={{
            transform: "rotate(-90deg)",
            transformOrigin: "center",
            transition: "all .6s cubic-bezier(0.58, 0.16, 0.5, 1.14)",
            transitionDelay: ".3s",
          }}
        />
      </svg>
      <div
        style={{
          width: `${dimension}px`,
          height: `${dimension}px`,
          position: "absolute",
          top: 0,
          lineHeight: `${dimension}px`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          as={"span"}
          variant={variant.score}
        >
          {score}
          {showPercent && <small>%</small>}
        </Text>
        {chartTitle && (
          <Text
            as="span"
            variant={variant.title}
            fontWeight="regular"
          >
            {chartTitle}
          </Text>
        )}
      </div>
    </div>
  );
};

export default RadialChart;
