//@ts-check
import { BlockStack, Card, InlineStack, SkeletonDisplayText, Text } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { statsSchemaItem } from "storeseo-schema/dashboard-stats";
import RadialChart from "../charts/RadialChart";
import StatisticCardDetailsPopover from "./StatisticCardDetailsPopover";

/**
 *
 * @param {{isLoading: boolean, title: string, description: string, resourceType: keyof typeof import("storeseo-enums/resourceType"), item: import("yup").InferType<typeof statsSchemaItem>}} props
 * @returns
 */
const StatisticCard = (props) => {
  const { isLoading, item, title, description, resourceType } = props;
  const { t } = useTranslation();

  return (
    <Card>
      <InlineStack
        gap="200"
        align="space-between"
        blockAlign="center"
      >
        <div style={{ flex: 1 }}>
          <BlockStack gap="400">
            <InlineStack
              gap="100"
              blockAlign="center"
              align="start"
            >
              <Text
                variant="bodySm"
                fontWeight="semibold"
                as="p"
              >
                {t(title)}
              </Text>
              <StatisticCardDetailsPopover
                resourceType={resourceType}
                item={item}
              />
            </InlineStack>
            <BlockStack gap="025">
              {isLoading ? (
                <SkeletonDisplayText size="large" />
              ) : (
                <Text
                  variant="headingXl"
                  as="p"
                  fontWeight="bold"
                >
                  {t(item?.total)}
                </Text>
              )}

              <Text
                variant="bodySm"
                as="p"
              >
                {t(description)}
              </Text>
            </BlockStack>
          </BlockStack>
        </div>
        <RadialChart
          score={parseInt(item?.overallScore || "0")}
          dimension={80}
          strokeWidth={20}
          radius={75}
          showPercent
          chartTitle={t("Overall")}
        />
      </InlineStack>
    </Card>
  );
};

export default StatisticCard;
