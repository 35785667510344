import { sendRequestToCrisp } from "@/utility/crisp";
import { Badge, InlineStack } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { BANNER_VERIFY_STORE } from "storeseo-enums/cacheKeys";
import DismissableBanner from "../common/DismissableBanner";
import VerifyShild from "../svg/VerifyShild";

const VerifyStoreCard = () => {
  const { t } = useTranslation();

  return (
    <DismissableBanner
      title={
        <InlineStack
          gap="200"
          blockAlign="center"
        >
          <VerifyShild /> {t("Verify your store")} <Badge tone="attention">{t("Unverified")}</Badge>
        </InlineStack>
      }
      content={t("Contact us to double your product limit and remove our branding from HTML sitemap page.")}
      bannerKey={BANNER_VERIFY_STORE}
      primaryAction={{
        content: t("Chat with Us Live!"),
        onAction: () =>
          sendRequestToCrisp("I would like to verify my store with StoreSEO and increase my product limit"),
      }}
    />
  );
};

export default VerifyStoreCard;
