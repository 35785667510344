import { Button, Listbox, Popover } from "@shopify/polaris";
import { isEmpty, isNull, snakeCase } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function SelectInput({
  title,
  options,
  selected = null,
  onSelect = () => {},
  selectedTitle = "",
  disclosure = "",
  variant = undefined,
  icon = undefined,
  labelHidden = false,
  disabled = false,
}) {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(null);
  const [buttonText, setButtonText] = useState("");
  const { t } = useTranslation();

  const handleOpenPicker = () => {
    setPickerOpen(true);
  };

  const handleClosePicker = () => {
    setPickerOpen(false);
  };

  const handleSegmentSelect = (value) => {
    let idx = options.findIndex((op) => op.value === value);
    setSelectedSegmentIndex(idx);
    onSelect(options[idx]);
    handleClosePicker();
  };

  useEffect(() => {
    if (selected && !isEmpty(options)) {
      let idx = options.findIndex((o) => o.value === selected.value);
      setSelectedSegmentIndex(idx);
    } else {
      setSelectedSegmentIndex(null);
    }
  }, [selected, options]);

  useEffect(() => {
    const isSelected = !isNull(selectedSegmentIndex);
    const btnContent = !isSelected
      ? title
      : (selectedTitle ? selectedTitle + " " : "") + options[selectedSegmentIndex]?.label;

    setButtonText(btnContent);
  }, [selectedSegmentIndex]);

  const activator = (
    <Button
      onClick={handleOpenPicker}
      disclosure={disclosure || (pickerOpen ? "up" : "down")}
      variant={variant}
      icon={icon}
      disabled={disabled}
    >
      {!labelHidden && t(buttonText)}
    </Button>
  );

  const segmentList =
    options.length > 0
      ? options.map(({ label, value }) => {
          const selected = options[selectedSegmentIndex]?.value === value;

          return (
            <Listbox.Option
              key={`${snakeCase(name)}-${value}`}
              value={value}
              selected={selected}
            >
              <Listbox.TextOption selected={selected}>{t(label)}</Listbox.TextOption>
            </Listbox.Option>
          );
        })
      : null;

  const listboxMarkup = (
    <Listbox
      customListId={snakeCase(name)}
      enableKeyboardControl
      onSelect={handleSegmentSelect}
    >
      {segmentList}
    </Listbox>
  );

  return (
    <Popover
      active={pickerOpen}
      activator={activator}
      ariaHaspopup="listbox"
      preferredAlignment="left"
      autofocusTarget="first-node"
      onClose={handleClosePicker}
    >
      <Popover.Pane fixed>
        <div
          style={{
            alignItems: "stretch",
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            padding: "var(--p-space-200) 0",
          }}
        >
          {listboxMarkup}
        </div>
      </Popover.Pane>
    </Popover>
  );
}

export default SelectInput;
